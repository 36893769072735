import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import './IndividualProject.scss'
import { generatePath, useParams } from 'react-router-dom'
import { Container, Tab, Tabs } from 'react-bootstrap'
import {
  BackLink,
  LoadingWrap, ProjectAirdropDetails,
  ProjectHero,
  ProjectIDODetails,
  ProjectStats,
  RoundButton,
  SEOTags,
  SwapForm,
  SwapNodesForm,
  WhitelistBadge,
  WhitelistModal,
  WrongNetworkBlock,
} from '@components'
import { IUseWhitelistArgs, useWhitelist, WhitelistStatuses } from '@contracts/hooks/useWhitelist'
import { ReactComponent as LinkArrow } from '@assets/link-arrow.svg'
import { usePresale } from '@contracts/hooks/usePresale'
import { useApproval, useNetwork, useNotify, useWalletContext } from '@firestarter-private/firestarter-library'
import { useProjectsState } from '@contracts/hooks/useProjects'
import { useReactiveDate } from '@hooks/useReactiveDate'
import {
  ProjectStatusesProps,
  ProjectStatusProp,
  ProjectTypes,
} from '@components/Project/types'
import { normalizeStatus } from '@api/projects/mapping'
import { projectGetters } from '@contracts/getters/projectGetters'
import { getIsSingleApprovalToken } from '@contracts/address'
import { maxUint256String, SECOND } from '@firestarter-private/firestarter-library/lib/constants'
import { createSAFTDocContent, SAFTfonts } from '@components/Project/SAFTModal/contents/pdf'
import { generatePdfAndOpen } from '@utils/pdfMake'
import { RoutesPaths } from '@router/constants'
import { OGProperties } from '@/seo-content/types'
import { balanceToNumber } from '@firestarter-private/firestarter-library/lib/utils/bigNumbers'
import { IUsePresaleArgs, IWhitelistUserData } from '@contracts/hooks/usePresale/types'
import { isEthNetwork } from '@firestarter-private/firestarter-library/lib/utils/networks'
import { INotifyTxCallbacks } from '@firestarter-private/firestarter-library/lib/hooks'
import { projectBannerThumb } from '@constants'
import { Layout } from '@/services/CMS/components'
import { useCMSContent } from '@/services/CMS/hooks/useCMSContent'
import { IWhitelistRequestFormData } from '@api/whitelist/types'
import classNames from 'classnames'
import { useLinkingStatus } from '@contracts/hooks/useLinkingStatus'
import { useKYC } from '@contracts/hooks/useKYC'
import { KYCStatuses } from '@api/kyc/types'

interface ParamTypes {
  id: string
}

enum ProjectTabs {
  PROJECT = 'PROJECT',
  TOKEN_ECONOMICS = 'TOKEN_ECONOMICS',
  IDO_DETAILS = 'IDO_DETAILS',
}

export const IndividualProject = () => {
  const { id } = useParams<ParamTypes>()

  const { loading, currentProject: project, getProjectById } = useProjectsState()

  useEffect(() => {
    if (id !== project?.id || !project) {
      getProjectById(id)
    }
  }, [id, project])

  const currentDate = useReactiveDate()

  const statusMessage: ProjectStatusProp = useMemo(() => {
    if (!project) return ProjectStatusesProps['Coming Soon']
    return normalizeStatus({
      status: project.presale.status,
      whitelistStart: project.whitelisting.starts_at,
      whitelistEnd: project.whitelisting.end_at,
      privatePresaleStart: project.presale.private_starts_at,
      privatePresaleEnd: project.presale.private_end_at,
      publicPresaleStart: project.presale.public_starts_at,
      publicPresaleEnd: project.presale.public_end_at,
    })
  }, [project, currentDate])

  const isExternalPresale = useMemo(() => (project ? projectGetters.getIsExternalPresale(project) : false), [project])
  const isNFTClaim = useMemo(() => (project ? projectGetters.getIsNFTClaimProject(project) : false), [project])

  const isPrivatePresaleInProgress = useMemo(
    () => statusMessage === ProjectStatusesProps['Guaranteed Live'],
    [statusMessage],
  )
  const isPublicPresaleInProgress = useMemo(() => statusMessage === ProjectStatusesProps['FCFS Live'], [statusMessage])
  const isPresaleInProgress = useMemo(
    () => isPublicPresaleInProgress || isPrivatePresaleInProgress,
    [isPublicPresaleInProgress, isPrivatePresaleInProgress],
  )

  const { account } = useWalletContext()

  const { checkIfSelected, getNetwork } = useNetwork()
  const isProjectNetworkSelected = useMemo(
    () => (project ? checkIfSelected(project.chainId) : false),
    [project, checkIfSelected],
  )
  const projectNetwork = useMemo(() => (project ? getNetwork(project.chainId) : null), [project])
  const isEthProject = useMemo(() => (project ? isEthNetwork(project.chainId) : false), [project])

  const { allowance, onApprove } = useApproval(
    ...(isProjectNetworkSelected && isEthProject
      ? [project?.presale.fund_token.address, project?.presale.presale_contract_address]
      : []),
  )

  const isSingleApproval = useMemo(
    () => !!project?.presale.fund_token.address && getIsSingleApprovalToken(project.presale.fund_token.address),
    [project],
  )

  const handleApprove = useCallback(
    async (amount?: string, callbacks: INotifyTxCallbacks = {}) => {
      if (!project || !project.presale.fund_token.address) return

      if (!isSingleApproval) {
        await onApprove(amount, callbacks)
        return
      }

      if (!allowance.isZero()) {
        await onApprove('0')
      }
      await onApprove(maxUint256String, callbacks)
    },
    [project, isSingleApproval, allowance, onApprove],
  )

  const whitelistArgs = useMemo<IUseWhitelistArgs>(() => {
    const contractAddress = isEthProject
      ? project?.presale.whitelist_contract_address
      : project?.presale.whitelist_program_id

    return {
      projectId: id,
      contract: isProjectNetworkSelected ? contractAddress : undefined,
      idlName: isProjectNetworkSelected && !isEthProject ? project?.presale.whitelist_idl_name : undefined,
    }
  }, [isEthProject, isProjectNetworkSelected, project, id])

  const { KYCStatus } = useKYC()

  const {
    whiteListStatus,
    isKycPassed,
    airdropAmount,
    publicMaxAllocation,
    privateMaxAllocation,
    privatePresaleAllowed,
    merkleProof,
    whitelistAccountAddress,
    applyToWhitelist,
  } = useWhitelist(whitelistArgs)

  const { loading: linkingLoading, data} = useLinkingStatus()
  const isNeedToShowLinkingWarning = project?.network === 'SUI' && !data?.sui_wallet_address

  const { notify } = useNotify()

  const onApplyToWhitelist = useCallback(
    async (data: IWhitelistRequestFormData) => {
      const status = await applyToWhitelist(data)
      if (status) {
        notify?.notification({
          type: 'success',
          message: `Thank you for your application, it is under review. 
        Please check back to view your status once whitelisting is complete.`,
          autoDismiss: 5000,
        })
      }
      return status
    },
    [applyToWhitelist, notify],
  )

  const presaleArgs = useMemo<IUsePresaleArgs>(() => {
    if (!project || !isProjectNetworkSelected || id !== project.id) return {}
    const fundTokenAddress = project.presale.fund_token.address
    const rewardTokenAddress = project.presale.reward_token.address
    if (isEthProject) {
      return {
        presaleAddress: project.presale.presale_contract_address,
        fundTokenAddress,
        rewardTokenAddress,
        options: {
          isRefundable: project.settings.is_refundable,
          isExternalVesting: project.settings.is_external_vesting,
          projectType: project.project_type
        }
      }
    }
    return {
      presaleAddress: project.presale.presale_program_id,
      fundTokenAddress,
      rewardTokenAddress,
      whitelistDataAccount: whitelistAccountAddress,
      presaleIdlName: project.presale.presale_idl_name,
    }
  }, [id, project, isProjectNetworkSelected, isEthProject, whitelistAccountAddress])

  const {
    loadingInfo,
    fundTokenInfo,
    rewardTokenInfo,
    totalSwapAmount,
    fundsSwapped,
    totalRewardsAmount,
    swappedByUser,
    swappedPublicByUser,
    swappedPrivateByUser,
    swapExchangeRate,
    participants,
    closePeriod,
    fetchActualInfo,
    onDeposit,
    onDepositPrivate,
  } = usePresale(presaleArgs)

  const fetchingInterval = useRef<ReturnType<typeof setInterval> | null>(null)

  useEffect(() => {
    if (!fetchingInterval.current && isPresaleInProgress) {
      fetchingInterval.current = setInterval(fetchActualInfo, 10 * SECOND)
    }

    return () => {
      fetchingInterval.current && clearInterval(fetchingInterval.current)
      fetchingInterval.current = null
    }
  }, [isPresaleInProgress, fetchActualInfo])

  const isClosePeriod = useMemo<boolean>(() => {
    if (!isEthProject) return false
    if (!project?.presale.public_end_at || !closePeriod) return false

    const closePeriodStarts = +project.presale.public_end_at - closePeriod
    return +currentDate > closePeriodStarts && +currentDate < +project.presale.public_end_at
  }, [project, closePeriod, currentDate, isEthProject])

  const isOpenAccessPeriod = useMemo(() => {
    if (!project?.presale.public_end_at || !project.presale.open_access_period) return false
    const openAccessStarts = +project.presale.public_end_at - project.presale.open_access_period
    return +currentDate > openAccessStarts && +currentDate < +project.presale.public_end_at
  }, [project, currentDate])

  const getMerkleUserData = useCallback(
    (account): IWhitelistUserData => {
      if (isOpenAccessPeriod) {
        return {
          isKycPassed: KYCStatus === KYCStatuses.approved,
          wallet: account,
          allowedPrivateSale: false,
          privateMaxAlloc: '0',
          publicMaxAlloc: '0'
        }
      }
      return {
        isKycPassed: !!isKycPassed,
        wallet: account,
        allowedPrivateSale: privatePresaleAllowed,
        ...(airdropAmount ? {
          airdrop: airdropAmount.toFixed(0, 1),
        } : {}),
        privateMaxAlloc: privateMaxAllocation.toFixed(0, 1),
        publicMaxAlloc: publicMaxAllocation.toFixed(0, 1),
      }
    },
    [
      KYCStatus,
      isOpenAccessPeriod,
      isKycPassed,
      privatePresaleAllowed,
      airdropAmount,
      privateMaxAllocation,
      publicMaxAllocation
    ],
  )

  const depositMethod = useCallback(
    async (amount: string, callbacks: INotifyTxCallbacks = {}) => {
      if (!project || !account) return
      if (isEthProject) {
        if (!merkleProof && !isOpenAccessPeriod) return

        const userData = getMerkleUserData(account)
        const result = await onDeposit({
          amount,
          userData,
          merkleProof: isOpenAccessPeriod ? ['0x00'] : merkleProof,
          callbacks,
        })
        await fetchActualInfo()
        return result
      }

      const result = await onDeposit({ amount, callbacks })
      await fetchActualInfo()
      return result
    },
    [project, account, isEthProject, onDeposit, getMerkleUserData, fetchActualInfo, merkleProof, isOpenAccessPeriod],
  )

  const depositPrivateMethod = useCallback(
    async (amount: string, callbacks: INotifyTxCallbacks = {}) => {
      if (!project || !account) return
      if (isEthProject) {
        if (!merkleProof) return

        const userData = getMerkleUserData(account)

        const result = await onDepositPrivate({
          amount,
          userData,
          merkleProof,
          callbacks,
        })
        await fetchActualInfo()
        return result
      }

      const result = await onDepositPrivate({ amount, callbacks })
      await fetchActualInfo()
      return result
    },
    [project, account, isEthProject, onDepositPrivate, getMerkleUserData, fetchActualInfo, merkleProof],
  )

  const downloadTermsAndConditions = useCallback(async () => {
    if (!project || !project.saft) {
      return
    }
    const docDefinition = createSAFTDocContent({
      projectName: project.name,
      registrationData: project.saft.company_registration_data,
      exchangeRate: project.saft.exchange_rate,
      companyOfficialName: project.saft.official_company_name,
      rewardTokenName: project.presale.reward_token.name,
      fundTokenName: project.presale.fund_token.name,
      contactEmail: project.saft.contact_email,
    })

    await generatePdfAndOpen({
      docDefinition,
      fonts: SAFTfonts,
    })
  }, [project])

  const [activeTab, setActiveTab] = useState<keyof typeof ProjectTabs>(ProjectTabs.PROJECT)
  const cmsContent = useCMSContent(project?.info.description_content_url)
  const isLegacy = useMemo(() => project && projectGetters.getIsLegacyProject(project), [project])

  const SwapFormComponent = useMemo(
    () => project?.project_type === ProjectTypes.nodes_sale ? SwapNodesForm : SwapForm,
    [project?.project_type]
  )

  return (
    <>
      <div className="project-page page">
        <SEOTags
          url={`${window.location.origin}${generatePath(RoutesPaths.PROJECT, { id })}`}
          title={`| Project ${project?.name || ''}`}
          properties={[
            {
              property: OGProperties.title,
              content: `FireStarter | Project ${project?.name || ''}`,
            },
            {
              property: OGProperties.description,
              content: 'Swap Tokens during Project Campaign and receive rewards',
            },
            {
              property: OGProperties.url,
              content: `${window.location.origin}${generatePath(RoutesPaths.PROJECT, { id })}`,
            },
          ]}
        />
        <LoadingWrap loading={loading || linkingLoading || (isProjectNetworkSelected && loadingInfo) }>
          {project && (
            <Container>
              <div className="project-grid">
                <section className="project-grid__top banner-section">
                  <BackLink to={RoutesPaths.PROJECTS}>Projects</BackLink>
                  <ProjectHero
                    logo={project.assets.logo_image_url}
                    banner={project.assets.banner_image_url ?? projectBannerThumb}
                    status={statusMessage}
                    network={project.network}
                    projectType={project.project_type}
                    fundTokenName={project.presale.fund_token.name}
                    name={project.name}
                    socialLinks={project.social_links}
                    onDownloadTerms={project.saft && downloadTermsAndConditions}
                  />
                </section>
                <section className="project-grid__aside presale-section">
                  <ProjectStats
                    totalAmount={balanceToNumber(totalSwapAmount, fundTokenInfo.decimals)}
                    totalRewards={project.project_type === ProjectTypes.token_airdrop
                      ? project.stats?.airdrop_amount
                      : balanceToNumber(totalRewardsAmount, rewardTokenInfo.decimals)}
                    fundsSwapped={balanceToNumber(fundsSwapped, fundTokenInfo.decimals)}
                    projectType={project.project_type}
                    participants={participants}
                    fundTokenName={project.presale.fund_token.name}
                    rewardTokenName={project.presale.reward_token.name}
                    swapExchangeRate={
                      isEthProject ? balanceToNumber(swapExchangeRate, 0) : balanceToNumber(swapExchangeRate, 0) / 1e6
                    }
                    status={statusMessage}
                    whitelistOpens={project.whitelisting.starts_at}
                    whitelistCloses={project.whitelisting.end_at}
                    privateOpens={project.presale.private_starts_at}
                    privateCloses={project.presale.private_end_at}
                    publicOpens={project.presale.public_starts_at}
                    publicCloses={project.presale.public_end_at}
                    airdropOpens={project.presale.airdrop_starts_at}
                    airdropCloses={project.presale.airdrop_end_at}
                    baseAllocation={project.stats?.base_allocation}
                    isExternalPresale={isExternalPresale}
                    isProjectNetworkSelected={isProjectNetworkSelected}
                    expanded
                  >
                    {!isProjectNetworkSelected && !isExternalPresale && project.project_type !== ProjectTypes.token_airdrop ? (
                      <WrongNetworkBlock prefix={'To see full stats'} expectedNetwork={projectNetwork!} embedded />
                    ) : (
                      <div className="mt-5">
                        <LoadingWrap loading={!whiteListStatus}>
                          {whiteListStatus === WhitelistStatuses.not_submitted &&
                          statusMessage === ProjectStatusesProps['Registration Open'] ? (
                            <>
                              { isNeedToShowLinkingWarning ? (
                                <div className={'text-center'}>
                                  <div
                                    className={classNames(
                                      'linking-message linking-message--warning text-center mt-3',
                                      { hidden: !isExternalPresale },
                                    )}
                                  >
                                    <span>To participate you need to link SUI wallet to your account</span>
                                  </div>
                                  <RoundButton to={RoutesPaths.ACCOUNT.LINKING_WALLETS} className="mt-3" size="large">Link wallet</RoundButton>
                                </div>
                              ) : (
                                <WhitelistModal project={project} applyToWhitelist={onApplyToWhitelist} />
                              )}
                            </>
                          ) : (
                            <WhitelistBadge status={whiteListStatus!} />
                          )}
                        </LoadingWrap>
                      </div>
                    )}
                  </ProjectStats>
                  {!!project &&
                    isPresaleInProgress &&
                    !isExternalPresale &&
                    (isProjectNetworkSelected ? (
                      <SwapFormComponent
                        totalSwapAmount={totalSwapAmount}
                        fundsSwapped={fundsSwapped}
                        publicMaxAllocation={publicMaxAllocation}
                        privateMaxAllocation={privateMaxAllocation}
                        exchangeRate={swapExchangeRate}
                        privatePresaleAllowed={privatePresaleAllowed}
                        swappedByUser={swappedByUser}
                        swappedPrivateByUser={swappedPrivateByUser}
                        swappedPublicByUser={swappedPublicByUser}
                        balance={fundTokenInfo.amount}
                        fundsDecimals={fundTokenInfo.decimals}
                        allowance={allowance}
                        whiteListStatus={whiteListStatus}
                        projectId={project.id}
                        fundToken={project.presale.fund_token}
                        isPrivatePhaseInProgress={isPrivatePresaleInProgress}
                        isPublicPhaseInProgress={isPublicPresaleInProgress}
                        isClosePeriod={isClosePeriod}
                        isOpenAccess={isOpenAccessPeriod}
                        isSingleApproval={isSingleApproval}
                        isEthProject={isEthProject}
                        onApprove={handleApprove}
                        onDeposit={depositMethod}
                        onDepositPrivate={depositPrivateMethod}
                      />
                    ) : (
                      !isExternalPresale && (
                        <WrongNetworkBlock prefix={'To participate in the project'} expectedNetwork={projectNetwork!} />
                      )
                    ))}
                  {isExternalPresale && isPresaleInProgress && (
                    <div className={'external-presale-navigation-block'}>
                      <div className={'external-presale-navigation-block__title'}>Project presale happening on external
                        resource:
                      </div>
                      <RoundButton href={project.presale.external_presale_link} className="mt-5" size="large">
                        Go to the pre-sale <LinkArrow />
                      </RoundButton>
                    </div>
                  )}
                </section>
                <section className="project-grid__bottom description-section">
                  <LoadingWrap loading={!isLegacy && !cmsContent}>
                    <Tabs
                      id="project-content-tabs"
                      className="fs-tabs-rounded project-content-tabs"
                      activeKey={activeTab}
                      onSelect={(key) => setActiveTab(key as keyof typeof ProjectTabs)}
                    >
                      <Tab title="Project" eventKey={ProjectTabs.PROJECT}>
                        {isLegacy ? (
                          <div dangerouslySetInnerHTML={{ __html: project.info.legacy?.description || '' }}></div>
                        ) : (
                          cmsContent && <Layout {...cmsContent.descriptionContent} />
                        )}
                      </Tab>
                      {!isLegacy && cmsContent && (
                        <Tab title="Token Economics" eventKey={ProjectTabs.TOKEN_ECONOMICS}>
                          <Layout {...cmsContent.tokenEconomicsContent} />
                        </Tab>
                      )}
                      <Tab title="IDO Details" eventKey={ProjectTabs.IDO_DETAILS}>
                        {project.project_type === ProjectTypes.token_airdrop ? (
                          <ProjectAirdropDetails
                            tokenName={project.presale.reward_token.name}
                            airdropAmount={project.stats?.airdrop_amount}
                            airdropDate={project.presale.airdrop_starts_at}
                          />
                        ) : (
                          <ProjectIDODetails
                            fundTokenName={project.presale.fund_token.name}
                            rewardTokenName={project.presale.reward_token.name}
                            totalAmount={
                              project.stats?.fundraise_goal ?? balanceToNumber(totalSwapAmount, fundTokenInfo.decimals)
                            }
                            exchangeRate={
                              project.stats?.price_per_token ?? (isEthProject
                                ? balanceToNumber(swapExchangeRate, 0)
                                : balanceToNumber(swapExchangeRate, 0) / 1e6)
                            }
                            baseAllocation={project.stats?.base_allocation}
                            releaseScheduleUrl={project.presale.release_schedule}
                            isExternalPresale={isExternalPresale}
                            externalPresaleClaimLink={
                              project.presale.external_claim_link || project.presale.external_presale_link
                            }
                          />
                        )}
                        {!isProjectNetworkSelected && !isExternalPresale && project.project_type !== ProjectTypes.token_airdrop && (
                          <>
                            <div className="py-3"></div>
                            <WrongNetworkBlock
                              prefix={'Some values could be unavailable on current network, please try to'}
                              expectedNetwork={projectNetwork!}
                            />
                          </>
                        )}
                      </Tab>
                    </Tabs>
                  </LoadingWrap>
                </section>
              </div>
            </Container>
          )}
        </LoadingWrap>
      </div>
    </>
  )
}


import { useCallback, useEffect, useMemo, useState } from 'react'
import BigNumber from 'bignumber.js'
import { sendExceptionReport } from '@utils/errors'
import { useIsMounted } from '@hooks/useIsMounted'
import { BlockNumber, TransactionReceipt } from 'web3-core'
import { SECOND } from '@constants/dates'
import {
  IDepositArgs,
  IUsePresaleArgs,
  IUsePresaleReturn,
} from '@contracts/hooks/usePresale/types'
import {
  useProjectPresaleContract,
  useTokenInfo,
  useTransactions,
  useWalletContext,
} from '@firestarter-private/firestarter-library'
import { TransactionResult } from '@contracts/types'
import { getPresaleErrorAndSendReport } from '@contracts/hooks/usePresale/errors'
import { NetworkType } from '@firestarter-private/firestarter-library/lib/constants'
import { numericToUint256, toBigNumber } from '@firestarter-private/firestarter-library/lib/utils/bigNumbers'
import { INotifyTxCallbacks } from '@firestarter-private/firestarter-library/lib/hooks'
import { ProjectTypes } from '@components/Project/types'
import { IUseTokenInfoEVM } from '@firestarter-private/firestarter-library/lib/hooks/useTokenInfo/types'

export const usePresaleEVM = ({
  presaleAddress,
  fundTokenAddress,
  rewardTokenAddress,
  options
}: IUsePresaleArgs): IUsePresaleReturn => {
  const isMountedRef = useIsMounted()
  const fundToken = useTokenInfo(fundTokenAddress, NetworkType.evm)
  const rewardTokenStandard = useTokenInfo(rewardTokenAddress, NetworkType.evm)
  const [noVestingRewardsDecimals, setNoVestingRewardsDecimals] = useState(0)

  // TODO: replace with actual node token functionality
  // @ts-ignore
  const nodesRewardToken: IUseTokenInfoEVM = useMemo(() => ({
    token: null,
    amount: toBigNumber(0),
    decimals: 0,
    getTokenInfo(blockNumber?: BlockNumber): Promise<void> {
      return Promise.resolve()
    },
  }), [])
  const isNodesSale = useMemo(() => options?.projectType === ProjectTypes.nodes_sale, [options])

  // @ts-ignore
  const noVestingRewardToken: IUseTokenInfoEVM = useMemo(() => ({
    token: null,
    amount: toBigNumber(0),
    decimals: noVestingRewardsDecimals,
    getTokenInfo(blockNumber?: BlockNumber): Promise<void> {
      return Promise.resolve()
    },
  }), [noVestingRewardsDecimals])

  const rewardToken = useMemo(
    () => isNodesSale ? nodesRewardToken : rewardTokenStandard,
    [isNodesSale, nodesRewardToken, rewardTokenStandard]
  )

  const { account } = useWalletContext()
  const presaleContract = useProjectPresaleContract(presaleAddress, options)


  const [loadingInfo, setLoadingInfo] = useState(false)
  const [blockNumber, setBlockNumber] = useState<BlockNumber>('latest')
  const [privateSoldAmount, setPrivateSoldAmount] = useState<BigNumber>(new BigNumber(0))
  const [publicSoldAmount, setPublicSoldAmount] = useState<BigNumber>(new BigNumber(0))
  const [totalRewardsAmount, setTotalRewardsAmount] = useState<BigNumber>(new BigNumber(0))
  const [exchangeRate, setExchangeRate] = useState<BigNumber>(new BigNumber(0))
  const [accuracy, setAccuracy] = useState<BigNumber>(new BigNumber(10))
  const [swappedByUser, setSwappedByUser] = useState<BigNumber>(new BigNumber(0))
  const [swappedPrivateByUser, setSwappedPrivateByUser] = useState<BigNumber>(new BigNumber(0))
  const [participants, setParticipants] = useState(0)
  const [closePeriod, setClosePeriod] = useState<number>()

  const precisionDiff = useMemo(() => {
    const rewardsDecimals = options?.isExternalVesting && !rewardToken.token ? noVestingRewardsDecimals : rewardToken.decimals
    return rewardsDecimals - fundToken.decimals;
  }, [rewardToken.decimals, fundToken.decimals, options, noVestingRewardsDecimals, rewardToken.token]);

  const {
    callTransaction,
    sendTransaction
  } = useTransactions()

  const swapExchangeRate = useMemo(() => {
    return exchangeRate.dividedBy(accuracy)
  }, [exchangeRate, accuracy])

  const fundsSwapped = useMemo(() => {
    return privateSoldAmount
      .plus(publicSoldAmount)
      .times(swapExchangeRate)
      .dividedBy(new BigNumber(10).pow(precisionDiff))
  }, [privateSoldAmount, publicSoldAmount, swapExchangeRate, precisionDiff])

  const totalSwapAmount = useMemo(() => {
    return totalRewardsAmount
      .times(swapExchangeRate)
      .dividedBy(new BigNumber(10).pow(precisionDiff))
  }, [totalRewardsAmount, swapExchangeRate, precisionDiff])

  const swappedPublicByUser = useMemo(() => {
    return swappedByUser.minus(swappedPrivateByUser)
  }, [swappedByUser, swappedPrivateByUser])

  const resetInfo = () => {
    setTotalRewardsAmount(new BigNumber(0))
    setAccuracy(new BigNumber(10))
    setExchangeRate(new BigNumber(0))
    setClosePeriod(undefined)
  }

  const fetchInfo = useCallback(async () => {
    if (!presaleContract) {
      resetInfo()
      return
    }
    setLoadingInfo(true)

    try {
      const [
        initialRewards,
        rate,
        rateDecimals,
        closePeriodMillis,
        rewardsDecimals,
      ] = await Promise.all([
        callTransaction(presaleContract.methods.initialRewardAmount()),
        callTransaction(isNodesSale ? presaleContract.methods.price() : presaleContract.methods.exchangeRate()),
        callTransaction(presaleContract.methods.ACCURACY()),
        callTransaction(presaleContract.methods.closePeriod())
          .then((res) => +res * SECOND)
          .catch(() => undefined),
        callTransaction(presaleContract.methods.rewardTokenDecimals())
          .catch(() => undefined),
      ])

      if (isMountedRef.current) {
        setTotalRewardsAmount(new BigNumber(initialRewards))
        setAccuracy(isNodesSale ? toBigNumber(10).pow(6) : new BigNumber(rateDecimals))
        setExchangeRate(new BigNumber(rate))
        setClosePeriod(closePeriodMillis)
        setNoVestingRewardsDecimals(rewardsDecimals ?? 0)
      }
    } catch (err) {
      sendExceptionReport(err)
      isMountedRef.current && resetInfo()
    } finally {
      setLoadingInfo(false)
    }
  }, [presaleContract, isMountedRef, callTransaction, isNodesSale])

  useEffect(() => {
    fetchInfo()
  }, [presaleContract])

  const resetActualInfo = () => {
    setPrivateSoldAmount(new BigNumber(0))
    setPublicSoldAmount(new BigNumber(0))
    setSwappedByUser(new BigNumber(0))
    setSwappedPrivateByUser(new BigNumber(0))
    setParticipants(0)
  }

  const fetchActualInfo = useCallback(async () => {
    if (!account || !presaleContract || !fundToken.token) {
      resetActualInfo()
      return
    }

    try {
      const [
        publicSold,
        privateSold,
        { ftBalance },
        swappedPrivate,
        participantsCount
      ] = await Promise.all([
        callTransaction(
          presaleContract.methods.publicSoldAmount(),
          blockNumber
        ),
        callTransaction(
          presaleContract.methods.privateSoldAmount(),
          blockNumber
        ),
        callTransaction(
          presaleContract.methods.recipients(account),
          blockNumber
        ),
        callTransaction(
          presaleContract.methods.privateSoldFunds(account),
          blockNumber
        ),
        callTransaction(
          presaleContract.methods.participantCount(),
          blockNumber
        ),
        fundToken.getTokenInfo(blockNumber)
      ])

      if (isMountedRef.current) {
        setPublicSoldAmount(new BigNumber(publicSold));
        setPrivateSoldAmount(new BigNumber(privateSold));
        setSwappedByUser(new BigNumber(ftBalance));
        setSwappedPrivateByUser(new BigNumber(swappedPrivate))
        setParticipants(+participantsCount);
      }
    } catch (err) {
      sendExceptionReport(err)
      isMountedRef.current && resetActualInfo()
    }
  }, [
    account,
    presaleContract,
    isMountedRef,
    blockNumber,
    callTransaction,
    fundToken.token,
  ])

  useEffect(() => {
    fetchActualInfo()
  }, [account, presaleContract, fundToken.token])

  const getRefundablePeriod = useCallback(async (): Promise<Interval | null> => {
    if (!presaleContract) return null
    let [
      listTime,
      refundPeriod,
    ] = await Promise.all([
      callTransaction(
        presaleContract.methods.listTime(),
        blockNumber
      ),
      callTransaction(
        presaleContract.methods.refundPeriod(),
        blockNumber
      )
    ])

    listTime = Number(listTime) * 1000
    refundPeriod = Number(refundPeriod) * 1000

    return {
      start: listTime,
      end: listTime + refundPeriod
    }
  }, [
    presaleContract,
    callTransaction,
    blockNumber
  ])

  const getIsRefunded = useCallback(async () => {
    if (!presaleContract || !account) return false
    const data = await callTransaction(
      presaleContract.methods.recipients(account),
      blockNumber
    )

    return data.refunded
  }, [
    presaleContract,
    account,
    callTransaction,
    blockNumber
  ])

  const handleDeposit = useCallback(async ({
    amount,
    userData,
    merkleProof,
    callbacks = {},
  }: IDepositArgs): Promise<TransactionResult> => {
    if (!presaleContract) return getPresaleErrorAndSendReport('No contract')

    try {
      const receipt = await sendTransaction(
        presaleContract.methods.deposit(
          numericToUint256(amount, fundToken.decimals),
          userData,
          merkleProof
        ),
        callbacks
      ) as TransactionReceipt

      setBlockNumber(receipt.blockNumber)
      return {
        data: receipt.events?.Vested
      }
    } catch (error) {
      sendExceptionReport(error)
      return { error }
    }
  }, [presaleContract, fundToken.decimals, sendTransaction])

  const handleDepositPrivate = useCallback(async ({
    amount,
    userData,
    merkleProof,
    callbacks = {},
  }: IDepositArgs): Promise<TransactionResult> => {
    if (!presaleContract) return getPresaleErrorAndSendReport('No contract')

    try {
      const receipt = await sendTransaction(
        await presaleContract.methods.depositPrivateSale(
          numericToUint256(amount, fundToken.decimals),
          userData,
          merkleProof
        ),
        callbacks
      ) as TransactionReceipt

      setBlockNumber(receipt.blockNumber)
      return {
        data: receipt.events?.Vested
      }
    } catch (error) {
      sendExceptionReport(error)
      return { error }
    }
  }, [presaleContract, fundToken.decimals, sendTransaction])

  const handleRefund = useCallback(async (callbacks: INotifyTxCallbacks = {}) => {
    if (!presaleContract) return getPresaleErrorAndSendReport('No contract')

    try {
      const receipt = await sendTransaction(
        await presaleContract.methods.redeemFunds(),
        callbacks
      )

      if ('blockNumber' in receipt) {
        setBlockNumber(receipt.blockNumber)
      }
      return {
        data: receipt.events?.Refunded
      }
    } catch (error) {
      sendExceptionReport(error)
      return { error }
    }
  }, [presaleContract, sendTransaction])

  return {
    loadingInfo,
    totalSwapAmount,
    fundsSwapped,
    totalRewardsAmount,
    swapExchangeRate,
    swappedByUser,
    swappedPublicByUser,
    swappedPrivateByUser,
    participants,
    closePeriod,
    fetchActualInfo,
    getRefundablePeriod,
    getIsRefunded,
    onDeposit: handleDeposit,
    onDepositPrivate: handleDepositPrivate,
    onRefund: handleRefund,
    fundTokenInfo: fundToken,
    rewardTokenInfo: (rewardToken.token && !options?.isExternalVesting) ? rewardToken : noVestingRewardToken,
  }
}

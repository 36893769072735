import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { FormControl, Image, InputGroup } from 'react-bootstrap';
import {
  ApprovalSteps,
  RoundButton,
  CommonTooltip,
  TierStatus,
} from '@components/common'
import { WhitelistStatus, WhitelistStatuses } from '@contracts/hooks/useWhitelist';
import {
  balanceToCurrency,
  balanceToNumber,
  balanceToNumeric,
  numericToBalance,
  numericToUint256
} from '@utils/balanceFormatter';
import BigNumber from 'bignumber.js';
import { TokenInfo } from '../types'
import { useFlameTier } from '@contracts/hooks/useFlameTier/useFlameTier';
import { INotifyTxCallbacks } from '@firestarter-private/firestarter-library/lib/hooks';
import { ITransactionError, TransactionResult } from '@contracts/types'
import { ERROR_DISMISS_TIMEOUT, getStringTxError } from '@contracts/hooks/utils'
import { Divider } from '@/components'
import './SwapForm.scss'
import { toBigNumber } from '@firestarter-private/firestarter-library/lib/utils/bigNumbers'

interface Props {
  totalSwapAmount: BigNumber
  fundsSwapped: BigNumber
  publicMaxAllocation: BigNumber
  privateMaxAllocation: BigNumber
  privatePresaleAllowed: boolean
  swappedByUser: BigNumber
  swappedPrivateByUser: BigNumber
  swappedPublicByUser: BigNumber
  balance: BigNumber
  fundsDecimals: number
  allowance: BigNumber
  whiteListStatus: WhitelistStatus | null
  projectId: string
  fundToken: TokenInfo
  isPrivatePhaseInProgress: boolean
  isPublicPhaseInProgress: boolean
  isClosePeriod?: boolean
  isSingleApproval?: boolean
  isEthProject?: boolean
  onApprove: (amount?: string, callbacks?: INotifyTxCallbacks) => Promise<void>
  onDeposit: (amount: string, callbacks?: INotifyTxCallbacks) => Promise<TransactionResult | undefined>
  onDepositPrivate: (amount: string, callbacks?: INotifyTxCallbacks) => Promise<TransactionResult | undefined>
}

export const SwapForm: React.FC<Props> = ({
  totalSwapAmount,
  fundsSwapped,
  publicMaxAllocation,
  privateMaxAllocation,
  privatePresaleAllowed,
  swappedByUser,
  swappedPrivateByUser,
  swappedPublicByUser,
  balance,
  fundsDecimals,
  allowance,
  whiteListStatus,
  projectId,
  fundToken,
  onApprove,
  isPrivatePhaseInProgress,
  isPublicPhaseInProgress,
  isClosePeriod,
  isSingleApproval,
  isEthProject,
  onDeposit,
  onDepositPrivate,
}) => {

  const {
    userTierInfo,
    loading: loadingTier
  } = useFlameTier()

  const remainingFundsToSwap = useMemo(() => {
    return totalSwapAmount.minus(fundsSwapped)
  }, [totalSwapAmount, fundsSwapped])

  const allowedInClosePeriod = useMemo(() => {
    return (privatePresaleAllowed || !privateMaxAllocation.isZero()) || (!privateMaxAllocation.isZero() && !publicMaxAllocation.isZero())
  }, [
    privatePresaleAllowed,
    privateMaxAllocation,
    publicMaxAllocation,
  ])

  const availableAllocation = useMemo(() => {
    if (isPrivatePhaseInProgress) {
      if (!privatePresaleAllowed) return toBigNumber(0)
      return privateMaxAllocation.minus(swappedPrivateByUser)
    }
    if (isClosePeriod) {
      return publicMaxAllocation.plus(privateMaxAllocation).minus(swappedByUser)
    }
    if (isPublicPhaseInProgress && privatePresaleAllowed) {
      return toBigNumber(0)
    }
    return publicMaxAllocation.minus(swappedPublicByUser)
  }, [
    publicMaxAllocation,
    privateMaxAllocation,
    swappedPublicByUser,
    swappedPrivateByUser,
    swappedByUser,
    isPrivatePhaseInProgress,
    isPublicPhaseInProgress,
    isClosePeriod,
    privatePresaleAllowed,
  ])

  const maxSwapAmount = useMemo(() => {
    return balanceToNumeric(
      BigNumber.min(
        availableAllocation,
        balance,
        remainingFundsToSwap,
      ),
      fundsDecimals
    );
  }, [
    remainingFundsToSwap,
    availableAllocation,
    balance,
    fundsDecimals
  ])

  const [isApproving, setIsApproving] = useState(false)
  const [isSwapping, setIsSwapping] = useState(false)
  const [swappingError, setSwappingError] = useState<string | undefined>()
  const [amountToSwap, setAmountToSwap] = useState('0')

  const setSwapErrorMessage = (error: ITransactionError['error']) => {
    setSwappingError(getStringTxError(error))
    setTimeout(() => setSwappingError(undefined), ERROR_DISMISS_TIMEOUT)
  }

  const setMaxToSwap = useCallback(() => {
    setAmountToSwap(maxSwapAmount)
  }, [maxSwapAmount]);

  const disableApproval = useMemo(() => {
    return whiteListStatus !== WhitelistStatuses.passed
      || allowance.isGreaterThanOrEqualTo(numericToBalance(amountToSwap, fundsDecimals))
      || +amountToSwap <= 0
      || isApproving
  }, [
    whiteListStatus,
    allowance,
    amountToSwap,
    fundsDecimals,
    isApproving,
  ])

  const disableSwapping = useMemo(() => {
    return whiteListStatus !== WhitelistStatuses.passed
      || +amountToSwap <= 0
      || (isEthProject && !balanceToNumber(allowance, fundsDecimals))
      || numericToBalance(maxSwapAmount, fundsDecimals).isLessThan(numericToBalance(amountToSwap, fundsDecimals))
      || (isEthProject && allowance.isLessThan(numericToBalance(amountToSwap, fundsDecimals)))
      || (isPrivatePhaseInProgress && !privatePresaleAllowed)
      || ((isPublicPhaseInProgress && !isClosePeriod) && privatePresaleAllowed)
      || (isClosePeriod && availableAllocation.isZero())
      || isSwapping
  }, [
    maxSwapAmount,
    amountToSwap,
    allowance,
    whiteListStatus,
    fundsDecimals,
    isEthProject,
    isPrivatePhaseInProgress,
    isPublicPhaseInProgress,
    privatePresaleAllowed,
    isClosePeriod,
    availableAllocation,
    isSwapping
  ])

  const warningMessage = useMemo(() => {
    if (isPrivatePhaseInProgress && !privatePresaleAllowed) {
      return 'The sale for FCFS is not open yet'
    }
    if (isPublicPhaseInProgress && !isClosePeriod && publicMaxAllocation.isZero()) {
      return 'The sale by Guaranteed access is already over'
    }
    if (publicMaxAllocation.isZero() && privateMaxAllocation.isZero()) {
      return `Your wallet didn't win to have access for this sale`
    }
    if (numericToBalance(amountToSwap, fundsDecimals).isGreaterThan(remainingFundsToSwap)) {
      return 'The amount exceeds the remaining funds to raise'
    }
    if (numericToBalance(amountToSwap, fundsDecimals).isGreaterThan(availableAllocation)) {
      return 'The amount exceeds what is available for your Tier.'
    }
    if (numericToBalance(amountToSwap, fundsDecimals).isGreaterThan(balance)) {
      return 'The amount exceeds your balance of the tokens'
    }
    if (remainingFundsToSwap.isZero()) {
      return 'All the funds have already been raised'
    }
  }, [
    amountToSwap,
    balance,
    availableAllocation,
    fundsDecimals,
    remainingFundsToSwap,
    isPrivatePhaseInProgress,
    isPublicPhaseInProgress,
    privatePresaleAllowed,
    publicMaxAllocation,
    privateMaxAllocation,
    isClosePeriod
  ])

  const handleApprove = useCallback(async () => {
    if (disableApproval) return
    setIsApproving(true)
    await onApprove(numericToUint256(amountToSwap, fundsDecimals))
    setIsApproving(false)
  }, [disableApproval, onApprove, amountToSwap, fundsDecimals])

  const handleSwap = useCallback(async () => {
    if (disableSwapping) {
      return
    }
    setIsSwapping(true)
    const depositMethod = isPrivatePhaseInProgress ? onDepositPrivate : onDeposit
    const result = await depositMethod(
      amountToSwap,
      {
        onHash: () => setAmountToSwap('0')
      }
    )
    setIsSwapping(false)
    if (result && ('error' in result)) {
      setSwapErrorMessage(result.error)
    }
  }, [
    amountToSwap,
    onDeposit,
    onDepositPrivate,
    disableSwapping,
    isPrivatePhaseInProgress,
  ])

  return (
    <form className="swap-form tile">
      <TierStatus loading={loadingTier} userTierInfo={userTierInfo} />
      <Divider />
      <div className="info-list">
        <div>
          <dt className="name">Available swap amount</dt>
          <dd className="value"><big>{balanceToCurrency(availableAllocation, fundsDecimals)}</big> {fundToken.name}</dd>
        </div>
      </div>
      <div className="swap-form__controls">
        <InputGroup className='swap-form__input-group input-group-big'>
          <InputGroup.Prepend>
            <Image rounded src={fundToken.icon} />
          </InputGroup.Prepend>
          <FormControl
            placeholder="0.0"
            type="number"
            inputMode="numeric"
            min={0}
            value={amountToSwap}
            onChange={(e) => setAmountToSwap(e.target.value)}
            isInvalid={disableSwapping}
            isValid={!disableSwapping}
          />
          <InputGroup.Append>
            <RoundButton size="small" color="DARK" onClick={setMaxToSwap}>
              MAX
            </RoundButton>
          </InputGroup.Append>
        </InputGroup>
        {!!warningMessage && (
          <div className='form-message form-message--warning text-center'>
            <span>{warningMessage}</span>
          </div>
        )}
        <p className="form-message form-message--no-padding text-center">
          You have {balanceToCurrency(swappedByUser, fundsDecimals)} {fundToken.name}&nbsp;
          swapped total from {balanceToCurrency(privateMaxAllocation.plus(publicMaxAllocation), fundsDecimals)} available for your TIER
        </p>
        <div className="swap-form__buttons">
          {
            isEthProject && <RoundButton
              size="large"
              disabled={disableApproval}
              onClick={handleApprove}
              loading={isApproving}
            >
              Approve
              {
                isSingleApproval && (
                  <CommonTooltip id="allowance-tip" placement="auto">
                    <p>
                      The {fundToken.name} token has a special allowance behavior. <br/>
                      Thus, it may take additional transaction to approve the spending of your tokens.
                    </p>
                  </CommonTooltip>
                )
              }
            </RoundButton>
          }
          <RoundButton
            size="large"
            disabled={disableSwapping}
            onClick={handleSwap}
            loading={isSwapping}
          >
            SWAP
          </RoundButton>
        </div>
        {isEthProject && <ApprovalSteps fillingCondition={disableApproval} />}
        <div
          className={classNames(
            'form-message form-message--error form-message--no-padding text-center mt-3',
            { hidden: !swappingError }
          )}
        >
          <span>{swappingError}</span>
        </div>
      </div>
    </form>
  )
}
